import { get } from 'lodash';
import { mapGetters } from 'vuex';

import { types as dashTypes } from '@/modules/dashboard/store/types';

const goToOpenedPatient = {
  props: {
    returnToOpenedPatient: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      openedPatient: dashTypes.getters.OPENED_PATIENT,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID
    })
  },
  methods: {
    goToOpenedPatient() {
      const openedPatientId = get(this.openedPatient, 'id');
      const allowRedirectionToOpenedPatient = openedPatientId === this.currentPatientId;

      if (this.returnToOpenedPatient && allowRedirectionToOpenedPatient) {
        this.$router.push({
          name: 'PatientCard',
          params: {
            id: openedPatientId
          }
        });

        return true;
      }

      return false;
    }
  }
};

export default goToOpenedPatient;
