<template>
  <div class="diagnostic-overview">
    <div class="diagnostic-overview__title text-center">
      {{ $t('dashboard.photoAnalysis.label.simplifiedSkinDiagnostic') }}
    </div>
    <scores-spider-chart />
  </div>
</template>

<script>
import ScoresSpiderChart from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ScoresSpiderChart';

export default {
  name: 'DiagnosticOverviewAnalysisResults',
  components: { ScoresSpiderChart }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/text';

@media (max-width: 767px) {
  .diagnostic-overview {
    &__title {
      font-size: 15px;
      line-height: 17px;
    }
  }
}
</style>
