<template>
  <footer class="mobile-footer">
    <slot name="footer"></slot>
  </footer>
</template>

<script>
export default {
  name: 'MobileFixedFooter'
};
</script>

<style scoped>
@media (max-width: 767px) {
  .mobile-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
  }
}
</style>
