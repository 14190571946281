<template>
  <header class="full-width">
    <div class="simple-modal-header flex--space-between--center">
      <div class="simple-modal-header__title main-text">
        <slot name="header-title"></slot>
      </div>
      <div v-if="shouldShowCloseIcon" class="icon-close custom-icon" @click="closeModal"></div>
    </div>
    <div class="divider otherXS"></div>
  </header>
</template>

<script>
import goToOpenedPatient from '@/modules/dashboard/components/mixins/goToOpenedPatient';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'ModalHeader',
  mixins: [goToOpenedPatient],
  props: {
    currentModalName: {
      type: String,
      required: true
    },
    shouldShowCloseIcon: {
      type: Boolean,
      default: true
    },
    isCustomCloseHandler: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      if (this.isCustomCloseHandler) {
        this.$emit('close-modal');

        return;
      }

      if (!isMobileDevice()) {
        this.$modal.hide(this.currentModalName);

        return;
      }

      const isRedirectedToPatient = this.goToOpenedPatient();

      if (isRedirectedToPatient) {
        return;
      }

      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/scss/common/text';

.simple-modal-header {
  height: 50px;
  padding: 0 20px;
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

@media (max-width: 767px) {
  .simple-modal-header {
    &__title {
      font-weight: bold;
    }
  }
}
</style>
