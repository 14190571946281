<template>
  <modal
    name="quit-photo-analysis-warning-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :width="280"
    height="auto"
    :click-to-close="false"
    @before-open="beforeOpen"
  >
    <div class="quit-photo-analysis modal-container flex-column--align-center">
      <h3 class="quit-photo-analysis__title">
        {{ $t('dashboard.photoAnalysis.warning.areYouSure') }}
      </h3>
      <span class="quit-photo-analysis__hint">
        {{ $t('dashboard.photoAnalysis.warning.areYouSure2') }}
      </span>
      <div class="quit-photo-analysis__buttons-container flex--center--center">
        <button class="cancel-button mr20" @click="handleCancel">
          {{ $t('dashboard.action.cancel') }}
        </button>
        <button class="rounded-button-white" @click="handleYes">
          {{ $t('dashboard.photoAnalysis.action.quit') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import goToOpenedPatient from '@/modules/dashboard/components/mixins/goToOpenedPatient';

import { deletePatientPhoto } from '@/modules/dashboard/api';
import { isMobileDevice } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import { STORE_IMAGE_OPTIONS } from '@/modules/dashboard/api/constants';

export default {
  name: 'QuitPhotoAnalysisWarningModal',
  mixins: [goToOpenedPatient],
  data() {
    return {
      noAnswerHandler: null,
      yesAnswerHandler: null
    };
  },
  computed: {
    ...mapGetters({
      photoAnalysisPhotoId: dashTypes.getters.PHOTO_ANALYSIS_PHOTO_ID,
      photoAnalysisStoreImageOptions: dashTypes.getters.PHOTO_ANALYSIS_STORE_IMAGE_OPTION,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID
    })
  },
  methods: {
    ...mapActions({
      resetPhotoAnalysis: dashTypes.actions.RESET_PHOTO_ANALYSIS
    }),
    beforeOpen({ params: { noAnswerHandler, yesAnswerHandler } }) {
      this.noAnswerHandler = noAnswerHandler;
      this.yesAnswerHandler = yesAnswerHandler;
    },
    hideModal() {
      this.$modal.hide('quit-photo-analysis-warning-modal');
    },
    handleCancel() {
      if (this.noAnswerHandler && this.noAnswerHandler instanceof Function) {
        this.noAnswerHandler();
      }

      this.hideModal();
    },
    async handleYes() {
      if (this.yesAnswerHandler && this.yesAnswerHandler instanceof Function) {
        this.yesAnswerHandler();
      }

      if (this.photoAnalysisStoreImageOptions === STORE_IMAGE_OPTIONS.DO_NOT_STORE) {
        await deletePatientPhoto(this.currentPatientId, this.photoAnalysisPhotoId);
      }

      await this.resetPhotoAnalysis();

      if (!isMobileDevice()) {
        this.hideModal();

        return;
      }

      const isRedirectedToPatient = this.goToOpenedPatient();

      if (isRedirectedToPatient) {
        return;
      }

      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';

.rounded-button-white {
  min-width: 100px;
}

.quit-photo-analysis {
  padding: 30px 21px;

  &__title,
  &__hint {
    margin-bottom: 30px;

    font-weight: 300;
    color: $text-color;
  }

  &__title {
    font-size: 13px;
    line-height: 19px;
  }

  &__hint {
    width: 100%;
    padding: 12px 15px 14px;
    background-color: $beige;

    font-size: 11px;
    line-height: 16px;
  }
}
</style>
