<template>
  <div class="analysis-overview">
    <modal-header
      class="analysis-overview__header"
      current-modal-name="analysis-results-overview-modal"
      is-custom-close-handler
      @close-modal="beforeCloseModal"
    >
      <span slot="header-title">
        {{ $t('dashboard.photoAnalysis.label.analysisResultsOverview') }}
      </span>
    </modal-header>
    <section class="analysis-overview__description main-text overview-description">
      <p class="overview-description__text">
        {{
          $t('dashboard.photoAnalysis.label.quickOverviewOfPhotoAnalysis', [currentPatientFullName])
        }}
      </p>
      <p class="overview-description__text">
        {{ $t('dashboard.photoAnalysis.label.editGeneratedDiagnostic', [currentPatientFirstName]) }}
      </p>
    </section>
    <section class="analysis-overview__results analysis-results flex main-text">
      <diagnostic-overview-analysis-results class="analysis-results__diagnostic" />
      <recommended-ingredients-list
        :ingredients="recommendedIngredients"
        class="analysis-results__recommended-ingredients"
      />
    </section>
    <div class="divider otherXS"></div>
    <mobile-fixed-footer class="analysis-overview__footer flex--center--center">
      <button slot="footer" class="rounded-button-white" @click="goToResultsUpdate">
        {{ $t('action.next') }}
      </button>
    </mobile-fixed-footer>

    <quit-photo-analysis-warning-modal class="onlyXS" return-to-opened-patient />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import DiagnosticOverviewAnalysisResults from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/DiagnosticOverviewAnalysisResults';
import RecommendedIngredientsList from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/RecommendedIngredientsList';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';

import Ingredient from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Ingredient';
import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { handleRoutingInfo } from '@/modules/dashboard/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import {
  HIGHLY_RECOMMENDED_SELECTION_SCORE,
  MOST_RECOMMENDED_SELECTION_SCORE
} from '@/modules/dashboard/api/constants';

const PHOTO_ANALYSIS_RESULT_ROUTING = new RoutingInfo(
  'photo-analysis-result-update-modal',
  'PhotoAnalysisResultUpdate'
);

export default {
  name: 'AnalysisResultsOverview',
  components: {
    QuitPhotoAnalysisWarningModal,
    MobileFixedFooter,
    RecommendedIngredientsList,
    DiagnosticOverviewAnalysisResults,
    ModalHeader
  },
  computed: {
    ...mapGetters({
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      currentPatientFullName: dashTypes.getters.CURRENT_PATIENT_FULL_NAME,
      currentPatientFirstName: dashTypes.getters.CURRENT_PATIENT_FIRST_NAME,
      photoAnalysisResult: dashTypes.getters.PHOTO_ANALYSIS_RESULT
    }),
    recommendedIngredients() {
      const selections = get(this.photoAnalysisResult, 'treatFace.activesSelection', {});
      const ingredients = Object.entries(selections).reduce(
        (groupedIngredients, [ingredientName, { selection }]) => ({
          ...groupedIngredients,
          [selection]: [...(groupedIngredients[selection] || []), new Ingredient(ingredientName)]
        }),
        {}
      );

      return [
        ...(ingredients[HIGHLY_RECOMMENDED_SELECTION_SCORE] || []),
        ...(ingredients[MOST_RECOMMENDED_SELECTION_SCORE] || [])
      ];
    }
  },
  methods: {
    goToResultsUpdate() {
      handleRoutingInfo(PHOTO_ANALYSIS_RESULT_ROUTING, this.$router, this.$modal);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('analysis-results-overview-modal');
    },
    beforeCloseModal() {
      this.$modal.show('quit-photo-analysis-warning-modal', {
        noAnswerHandler: () => {
          this.$modal.show('analysis-results-overview-modal');
        }
      });

      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/common/text';

.analysis-overview {
  &__description {
    padding: 30px 40px 15px;
    text-align: left;
  }

  &__footer {
    height: 70px;
  }
}

.overview-description {
  &__text {
    margin-bottom: 15px;
  }
}

.analysis-results {
  &__diagnostic {
    width: 62.5%;
    padding: 30px 0 0;

    background-color: $background;
  }

  &__recommended-ingredients {
    width: 37.5%;
    padding: 30px 39px 30px;

    background-color: $hovered-button-color;
  }
}

@media (max-width: 767px) {
  .analysis-overview {
    background: $white;
    min-height: 100vh;
    padding: 50px 0 60px;

    &__header {
      position: fixed;
      top: 0;
      z-index: 21;

      background-color: $background;
    }

    &__description {
      padding: 30px 16px 20px;
    }

    &__results {
      flex-direction: column;
    }

    &__footer {
      height: 60px;
      z-index: 21;

      background-color: $white;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .analysis-results {
    &__diagnostic {
      width: 100%;
      padding: 20px 0 0;

      background-color: $background;
    }

    &__recommended-ingredients {
      width: 100%;
      padding: 30px 39px 20px;

      background-color: $hovered-button-color;
    }
  }
}
</style>
