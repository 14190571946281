<template>
  <div class="ingredients-list">
    <div class="ingredients-list__title">
      {{ $t('dashboard.analysisResults.label.recommendedIngredients') }}
    </div>
    <div
      ref="ingredientsListContainer"
      class="ingredients-list__ingredients ingredients__container"
    >
      <recommended-ingredient
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        class="ingredients-list__ingredient"
        :ingredient="ingredient"
      />
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import RecommendedIngredient from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/RecommendedIngredient';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'RecommendedIngredientsList',
  components: { RecommendedIngredient },
  props: {
    ingredients: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ingredientsScrollbar: null
    };
  },
  mounted() {
    if (isMobileDevice()) {
      return;
    }

    this.ingredientsScrollbar = new PerfectScrollbar(this.$refs.ingredientsListContainer, {
      suppressScrollX: true,
      wheelPropagation: false
    });
  }
};
</script>

<style lang="scss" scoped>
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.ingredients-list {
  &__title {
    margin-bottom: 20px;

    font-size: 15px;
    line-height: 17px;
  }

  &__ingredient {
    padding: 10px 0;
  }
}

.ingredients {
  &__container {
    height: 350px;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .ingredients {
    &__container {
      height: auto;
      overflow: visible;
    }
  }
}
</style>
