<template>
  <div class="recommended-ingredient flex--align-center">
    <div
      class="recommended-ingredient__icon"
      :style="{ 'background-color': ingredient.color }"
    ></div>
    <span class="recommended-ingredient__name">
      {{ $t(`dashboard.ingredients.${ingredient.name}`) }}
    </span>
  </div>
</template>

<script>
import Ingredient from '@/modules/dashboard/components/create-treatment-tab/treatments/treat/Ingredient';

export default {
  name: 'RecommendedIngredient',
  props: {
    ingredient: {
      type: Ingredient,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.recommended-ingredient {
  &__icon {
    width: 20px;
    height: 20px;

    border-radius: 3px;

    margin-right: 7px;
  }

  &__name {
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;

    color: $main-title-color;
  }
}
</style>
