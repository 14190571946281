<template>
  <div class="spider-chart flex--center--center">
    <img v-if="spiderChart" class="spider-chart__image" :src="spiderChartUrl" alt="spider chart" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'ScoresSpiderChart',
  computed: {
    ...mapGetters({
      spiderChart: dashTypes.getters.PHOTO_ANALYSIS_SPIDER_CHART
    }),
    spiderChartUrl() {
      return `data:image/png;base64,${this.spiderChart}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.spider-chart {
  &__image {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .spider-chart {
    &__image {
      width: 320px;
      height: 320px;
    }
  }
}
</style>
